<template>
  <div class="pl-2 pr-2">
    <UserFeedback :target="t('componentNames.courseCreator')" class="end mr-4 mt-1"></UserFeedback>

    <ValidationObserver ref="observer" v-slot="{ invalid }">
      <form v-if="localCourse">
        <div class="columns">
          <div class="column">
            <b-field grouped>
              <b-field expanded>
                <ValidationProvider rules="required|max:300" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('course.name')"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0], { max: 300 })"
                  >
                    <b-input type="text" v-model="localCourse.name" :has-counter="false" required></b-input>
                  </b-field>
                </ValidationProvider>
              </b-field>
              <b-field>
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('course.level')"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0])"
                  >
                    <b-select v-model="localCourse.level">
                      <option
                        v-for="level in mLevelCourse"
                        :key="level.id"
                        :value="level.id"
                      >{{ level.name }}</option>
                    </b-select>
                  </b-field>
                </ValidationProvider>
              </b-field>
            </b-field>
            <b-field>
              <b-field>
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('common.academy')"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0])"
                  >
                    <b-select v-model="localCourse.academy" expanded>
                      <option
                        v-for="(university, index) in afmConfig.universities"
                        :key="index"
                        :value="university"
                      >{{ university }}</option>
                    </b-select>
                  </b-field>
                </ValidationProvider>
              </b-field>
            </b-field>
            <b-field>
              <b-field :label="t('forms.tags')">
                <b-taginput
                  v-model="localCourse.tags"
                  ellipsis
                  icon="label"
                  :placeholder="t('common.add')"
                  :aria-close-label="t('common.remove')"
                ></b-taginput>
              </b-field>
            </b-field>
          </div>
          <div class="column">
            <b-field grouped>
              <b-field expanded :label="t('course.startDay')">
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('course.start')"
                    label-position="on-border"
                    expanded
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0])"
                  >
                    <b-datepicker
                      expanded
                      :value="startsDate"
                      @input="setStartDate"
                      icon="calendar-today"
                      :min-date="minDate"
                    ></b-datepicker>
                  </b-field>
                </ValidationProvider>
              </b-field>
              <b-field expanded :label="t('course.endDay')">
                <ValidationProvider rules="required" v-slot="{ errors, valid }">
                  <b-field
                    :label="t('course.end')"
                    expanded
                    label-position="on-border"
                    :type="{ 'is-danger': errors[0], 'is-success': valid }"
                    :message="t(errors[0])"
                  >
                    <b-datepicker
                      expanded
                      :value="endsDate"
                      @input="setEndsDate"
                      icon="calendar-today"
                      :min-date="minEndsDate"
                    ></b-datepicker>
                  </b-field>
                </ValidationProvider>
              </b-field>
            </b-field>
            <b-field v-if="localCourse.students">
              <b-collapse
                aria-id="selectTeacher"
                class="panel shadow-local"
                animation="none"
                v-model="teachersIsOpen"
              >
                <template #trigger="props">
                  <b-button
                    style="width: 16rem;"
                    :icon-right="props.open ? 'chevron-up' : 'chevron-down'"
                  >{{t('course.addTeachers')}}</b-button>
                </template>

                <div class="pt-3">
                  <ClassroomCreatorTeachers
                    :idTeachers="localCourse.teachers"
                    :multiCheck="true"
                    @addTeachers="addTeachers"
                    @removeTeachers="removeTeachers"
                    @save="teachersIsOpen = !teachersIsOpen"
                  />
                </div>
              </b-collapse>
            </b-field>
            <b-field v-if="localCourse.students">
              <b-collapse
                aria-id="selectStudents"
                class="panel shadow-local"
                animation="none"
                v-model="studentsIsOpen"
              >
                <template #trigger="props">
                  <b-button
                    style="width: 16rem;"
                    :icon-right="props.open ? 'chevron-up' : 'chevron-down'"
                  >{{t('course.addStudents')}}</b-button>
                </template>
                <div class="pt-3">
                  <ClassroomCreatorStudents
                    :idStudents="localCourse.students"
                    @addStudents="addStudents"
                    @removeStudents="removeStudents"
                    @save="studentsIsOpen = !studentsIsOpen"
                  />
                </div>
              </b-collapse>
            </b-field>
            <!-- <b-field v-if="idCourse && localCourse.classrooms">
              <b-collapse
                aria-id="selectClassrooms"
                class="panel"
                animation="slide"
                v-model="classroomsIsOpen"
              >
                <div slot="trigger" class="panel-heading">
                  <p>Zarządzaj zajęciami</p>
                </div>
                <div class="p-4">
                  <ListCheckClassrooms
                    :multiCheck="true"
                    :idClassrooms="localCourse.classrooms"
                    :availableClassrooms="localCourse.classrooms"
                    @addClassrooms="addClassrooms"
                    @removeClassrooms="removeClassrooms"
                  />
                </div>
              </b-collapse>
            </b-field>-->
          </div>
        </div>
        <div class="buttons">
          <b-button
            v-if="idCourse != null"
            :loading="isLoading"
            type="is-danger"
            icon-left="briefcase-remove"
            @click="confirmDelete"
          >{{t('course.remove')}}</b-button>
          <b-button
            icon-left="briefcase-check"
            :loading="isLoading"
            type="is-success"
            @click="saveCourse"
            :disabled="invalid"
          >{{t('course.save')}}</b-button>
        </div>
      </form>
    </ValidationObserver>
    <div v-if="idCourse != null" class="mt-3">
      <b-field class="file" :class="{'has-name': !!fileToUpload}">
        <b-upload v-model="fileToUpload.upladedFile" class="file-label" accept=".pdf">
          <span class="file-cta">
            <b-icon class="file-icon" icon="upload"></b-icon>
            <span class="file-label">{{t('course.clickToAddNewSyllabus')}}</span>
          </span>
          <span
            class="file-name"
            v-if="fileToUpload.upladedFile"
          >{{ fileToUpload.upladedFile.name }}</span>
        </b-upload>
        <p class="control">
          <b-button
            :loading="uploadLoading"
            :disabled="fileToUpload.upladedFile == null"
            type="is-primary"
            @click="uploadPdf"
          >{{t('common.sendAndSave')}}</b-button>
        </p>
      </b-field>
    </div>
    <b-loading v-model="isLoading" :is-full-page="false"></b-loading>
  </div>
</template>

<script>
import CommonHelpers from "@/mixins/commons";
import TypesHelpers from "@/mixins/types";
import { ValidationProvider, ValidationObserver } from "vee-validate";
//import { Action } from "@/store/config_actions";
import ClassroomCreatorStudents from "@/components/classroom/classroomCreate/ClassroomCreatorStudents";
import ClassroomCreatorTeachers from "@/components/classroom/classroomCreate/ClassroomCreatorTeachers";
//import ListCheckClassrooms from "@/components/listCheck/ListCheckClassrooms";
import { Action } from "../../store/config_actions";
import UserFeedback from '@/components/feedbacks/UserFeedback'

export default {
  name: "CourseCreator",
  mixins: [CommonHelpers, TypesHelpers],
  components: {
    ValidationProvider,
    ValidationObserver,
    ClassroomCreatorStudents,
    ClassroomCreatorTeachers,
    UserFeedback
    //ListCheckClassrooms,
  },

  props: {
    idCourse: { type: String, required: false },
  },

  data: function () {
    const today = new Date();
    return {
      isLoading: false,
      studentsIsOpen: false,
      teachersIsOpen: false,
      classroomsIsOpen: false,
      tags: [],
      fileToUpload: {
        description: "Sylabus",
        type: 999,
        upladedFile: null,
        tags: [],
      },
      minDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      radioButton: "",
      localCourse: null,
      removedClassroom: [],
      startStudentsId: [],
      startTeachersId: [],
      afmConfig: {},
      uploadLoading: false,
      newCourse: {
        type: 0,
        name: "",
        level: null,
        starts: null, //date-time
        ends: null, //date-time
        teachers: [],
        students: [],
        classrooms: [],
        tags: [],
        academy: "",
      },
    };
  },

  mounted() {
    this.getUsersToStorage();
    if (this.idCourse) {
      this.getCourse();
    } else {
      this.localCourse = JSON.parse(JSON.stringify(this.newCourse));
    }
    this.getAfmConfig();
  },

  watch: {},

  computed: {
    isAdmin() {
      if (this.session && this.session.roles.includes("Admin")) return true;
      else return false;
    },

    minEndsDate() {
      if (this.localCourse.starts) {
        return new Date(this.localCourse.starts);
      } else {
        return this.minDate;
      }
    },

    startsDate() {
      if (this.localCourse.starts) {
        return new Date(this.mDate(this.localCourse.starts));
      } else {
        return null;
      }
    },

    endsDate() {
      if (this.localCourse.ends) {
        return new Date(this.mDate(this.localCourse.ends));
      }
      return null;
    },
  },

  methods: {

    uploadPdf() {
      this.uploadLoading = true;

      this.$store
        .dispatch(Action.MEDIA_UPLOAD, this.fileToUpload)
        .then((response) => {
          this.$store
            .dispatch(Action.COURSE_ADD_PDF, { cid: this.idCourse, mid: response.id })
            .then((cresp) => {
              this.uploadLoading = false;
              this.mSuccessSnack(this.t('common.fileAdded'));
              this.$emit("course-edited", cresp)
              this.fileToUpload.upladedFile = null
            })
            .catch((error) => {
              this.uploadLoading = false;
              this.mDangerSnack(error.toString());
            });
        })
        .catch((error) => {
          this.uploadLoading = false;
          this.mDangerSnack(error.toString());
        });
    },



    setStartDate(value) {
      this.localCourse.starts = value;
    },

    setEndsDate(value) {
      this.localCourse.ends = value;
    },

    confirmDelete() {
      this.$buefy.dialog.confirm({
        title: this.t('course.remove'),
        message: this.t('course.removeConfirm'),
        onConfirm: () => this.deleteCourse(),
        type: "is-danger",
        hasIcon: true,
        cancelText: this.t('common.no'),
        confirmText: this.t('common.yes'),
      });
    },

    saveCourse() {
      var isAfterStart = this.mComparerDate(
        this.localCourse.starts,
        this.localCourse.ends
      );

      if (isAfterStart) {
        if (this.idCourse) {
          if (this.localCourse.teachers.length > 0) {
            this.updateCourse();
          } else {
            this.mDangerSnack(this.t('validation.courseWithouteTeacher'));
          }
        } else {
          this.addNewCourse();
        }
      }
    },

    formatDate(dateToFormat) {
      var dateString = this.mDate(dateToFormat.date);
      var timeString = this.mTime(dateToFormat.time);
      return this.mDateTimeRaw(dateString.concat(" ", timeString));
    },

    selectedExam(exam) {
      this.newClassroom.exam = exam;
    },

    addStudents(idStudents) {
      idStudents.forEach((element) => {
        let existElement = this.localCourse.students.includes(element);
        if (!existElement) {
          this.localCourse.students.push(element);
        }
      });
    },

    removeStudents(idStudents) {
      idStudents.forEach((element) => {
        let existElement = this.localCourse.students.includes(element);
        if (existElement) {
          this.localCourse.students.splice(
            this.localCourse.students.indexOf(element),
            1
          );
        }
      });
    },

    removeClassrooms(idClassrooms) {
      idClassrooms.forEach((element) => {
        //let existElement = this.localCourse.classrooms.includes(element);
        let existElement = this.removedClassroom.includes(element);
        if (!existElement) {
          this.removedClassroom.push(element);
        }
      });
    },

    addClassrooms(idClassrooms) {
      idClassrooms.forEach((element) => {
        //let existElement = this.localCourse.classrooms.includes(element);
        let existElement = this.removedClassroom.includes(element);
        if (existElement) {
          this.removedClassroom.splice(
            this.removedClassroom.indexOf(element),
            1
          );
        }
      });
    },

    addTeachers(idTeachers) {
      idTeachers.forEach((element) => {
        let existElement = this.localCourse.teachers.includes(element);
        if (!existElement) {
          this.localCourse.teachers.push(element);
        }
      });
    },

    removeTeachers(idTeachers) {
      idTeachers.forEach((element) => {
        let existElement = this.localCourse.teachers.includes(element);
        if (existElement) {
          this.localCourse.teachers.splice(
            this.localCourse.teachers.indexOf(element),
            1
          );
        }
      });
    },

    removeClassroomsFromCourse() {
      this.removedClassroom.forEach((element) => {
        let existElement = this.localCourse.classrooms.includes(element);
        if (existElement) {
          this.localCourse.classrooms.splice(
            this.localCourse.classrooms.indexOf(element),
            1
          );
        }
      });
    }, //COURSE_DELETE

    addNewCourse() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.COURSE_CREATE, this.localCourse)
        .then((payload) => {
          this.mSuccessSnack(this.t('course.added'));
          this.getUsersMultiple(payload.teachers, true, true, payload.id);
          this.getUsersMultiple(payload.students, true, false, payload.id);
          this.isLoading = false;
          this.$emit("courseAdd");
          this.localCourse = JSON.parse(JSON.stringify(this.newCourse));
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    differenceInCourse(usersId, startusersId, isTeacher, idC) {
      //this.maxUpdatedCourse = 0;
      let courseToRemove = startusersId.filter((x) => !usersId.includes(x));
      let courseToAdd = usersId.filter((x) => !startusersId.includes(x));

      // if (courseToRemove) {
      //   this.maxUpdatedCourse += courseToRemove.length;
      // }

      // if (courseToAdd) {
      //   this.maxUpdatedCourse = courseToAdd.length;
      // }

      if (courseToAdd && courseToAdd.length > 0) {
        this.getUsersMultiple(courseToAdd, true, isTeacher, idC);
      }

      if (courseToRemove && courseToRemove.length > 0) {
        this.getUsersMultiple(courseToRemove, false, isTeacher, idC);
      }
    },

    getUsersMultiple(idUsers, isAdd, isTeacher, idC) {
      idUsers.forEach((id) => {
        this.getUser(id, idC, isAdd, isTeacher);
      });
    },

    // getUser(idUser, idC, isAdd, isTeacher) {
    //   this.isLoading = true;
    //   this.$store
    //     .dispatch(Action.AUTH_GET_FULLINFO_USER, idUser)
    //     .then((payload) => {
    //       let localUser = {
    //         roles: payload.roles,
    //         currentEmail: payload.email,
    //         newEmail: payload.email,
    //         firstName: payload.firstName,
    //         lastName: payload.lastName,
    //         password: payload.password,
    //         studentYear: payload.studentYear,
    //         studentIndex: payload.studentIndex,
    //         academy: payload.academy,
    //         studentStudy: payload.studentStudy,
    //         studentSpec: payload.studentSpec,
    //         studentSemester: payload.studentSemester,
    //         studentCourses: payload.studentCourses,
    //         studentGroups: payload.studentGroups,
    //         teacherId: payload.teacherId,
    //         teacherAddress: payload.teacherAddress,
    //         teacherGroups: payload.teacherGroups,
    //         teacherCourses: payload.teacherCourses,
    //       };
    //       if (isTeacher) {
    //         if (isAdd) {
    //           if (!localUser.teacherCourses.includes(idC)) {
    //             localUser.teacherCourses.push(idC);
    //           }
    //         } else {
    //           if (localUser.teacherCourses.includes(idC)) {
    //             localUser.teacherCourses.splice(
    //               localUser.teacherCourses.indexOf(idC),
    //               1
    //             );
    //           }
    //         }
    //       } else {
    //         if (isAdd) {
    //           if (!localUser.studentCourses.includes(idC)) {
    //             localUser.studentCourses.push(idC);
    //           }
    //         } else {
    //           if (localUser.studentCourses.includes(idC)) {
    //             localUser.studentCourses.splice(
    //               localUser.studentCourses.indexOf(idC),
    //               1
    //             );
    //           }
    //         }
    //       }
    //       console.log(localUser);
    //       this.updateUser(localUser);
    //       this.isLoading = false;
    //     })
    //     .catch((error) => {
    //       this.mDangerSnack(error.toString());
    //       this.isLoading = false;
    //     });
    // },

    converterUser(payload) {
      let localUser = {
        roles: payload.roles,
        currentEmail: payload.email,
        newEmail: payload.email,
        firstName: payload.firstName,
        lastName: payload.lastName,
        password: payload.password,
        studentYear: payload.studentYear,
        studentIndex: payload.studentIndex,
        academy: payload.academy,
        studentStudy: payload.studentStudy,
        studentSpec: payload.studentSpec,
        studentSemester: payload.studentSemester,
        studentCourses: payload.studentCourses,
        studentGroups: payload.studentGroups,
        teacherId: payload.teacherId,
        teacherAddress: payload.teacherAddress,
        teacherGroups: payload.teacherGroups,
        teacherCourses: payload.teacherCourses,
      };
      return localUser;
    },

    getUser(idUser, idC, isAdd, isTeacher) {
      this.isLoading = true;

      let user = this.$store.state.auth.users.find((s) => s.id === idUser);
      if (user) {
        let localUser = this.converterUser(user);

        if (isTeacher) {
          if (isAdd) {
            if (!localUser.teacherCourses.includes(idC)) {
              localUser.teacherCourses.push(idC);
            }
          } else {
            if (localUser.teacherCourses.includes(idC)) {
              localUser.teacherCourses.splice(
                localUser.teacherCourses.indexOf(idC),
                1
              );
            }
          }
        } else {
          if (isAdd) {
            if (!localUser.studentCourses.includes(idC)) {
              localUser.studentCourses.push(idC);
            }
          } else {
            if (localUser.studentCourses.includes(idC)) {
              localUser.studentCourses.splice(
                localUser.studentCourses.indexOf(idC),
                1
              );
            }
          }
        }
        this.updateUser(localUser);
      }
      this.isLoading = false;
    },

    updateUser(localUser) {
      this.$store
        .dispatch(Action.AUTH_MODIFY_USER, localUser)
        .then(() => {
          //this.mSuccessSnack("Dane użytkownika zaaktualizowane");
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    updateCourse() {
      this.isLoading = true;
      //this.removeClassroomsFromCourse();
      this.$store
        .dispatch(Action.COURSE_MODIFY, this.localCourse)
        .then((payload) => {
          this.mSuccessSnack(this.t('common.changesSaved'));
          this.differenceInCourse(
            payload.students,
            this.startStudentsId,
            false,
            payload.id
          );
          this.differenceInCourse(
            payload.teachers,
            this.startTeachersId,
            true,
            payload.id
          );
          //this.$emit("courseUpdate");
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    deleteCourse() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.COURSE_DELETE, this.localCourse.id)
        .then(() => {
          this.mSuccessSnack(this.t('course.removed'));
          this.getUsersMultiple(
            this.startTeachersId,
            false,
            true,
            this.localCourse.id
          );
          this.getUsersMultiple(
            this.startTeachersId,
            false,
            false,
            this.localCourse.id
          );
          this.$emit("courseDelete");
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    getCourse() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.COURSE_GET, this.idCourse)
        .then((payload) => {
          this.localCourse = payload;
          this.startStudentsId = JSON.parse(JSON.stringify(payload.students));
          this.startTeachersId = JSON.parse(JSON.stringify(payload.teachers));
          this.minDate = new Date(payload.starts);
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },

    /**
     * Get AFM config
     */
    getAfmConfig() {
      this.isLoading = true;
      if (this.$store.state.afmconfig.config) {
        this.afmConfig = this.$store.state.afmconfig.config;
        this.isLoading = false;
      } else {
        this.$store
          .dispatch(Action.AFM_CONFIG_GET)
          .then((payload) => {
            this.afmConfig = payload;
            this.isLoading = false;
          })
          .catch((error) => {
            this.mDangerSnack(error.toString());
            this.isLoading = false;
          });
      }
    },

    getUsersToStorage() {
      if (!this.$store.state.auth.users) {
        this.$store
          .dispatch(Action.AUTH_GET_USERS)
          .then(() => { })
          .catch((error) => {
            this.mDangerSnack(error.toString());
            this.isLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.card {
  overflow: visible !important;
}
div.end {
  float: right;
}
.local-panel {
  background-color: #ededed;
  color: #363636;
  font-weight: 700;
  line-height: 1.25;
  padding: 0.75em 1em;
}

.shadow-local {
  box-shadow: none !important;
  border-radius: 0px !important;
}
</style>
