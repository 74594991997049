<template>
  <div class="card mt-4 ml-5 mr-5" style="min-height: 70vh;">
    <b-tabs :animated="false" v-model="selectedTab">
      <b-tab-item :label="t('common.courses')" icon="briefcase">
        <CourseList :refreshTrigger="refreshTrigger"></CourseList>
      </b-tab-item>
      <b-tab-item :label="t('componentNames.newCourse')" icon="briefcase-plus" :visible="selectedFirst">
        <CourseCreator @courseAdd="courseAdded"></CourseCreator>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import CommonHelpers from '@/mixins/commons'
import CourseCreator from './CourseCreator'
import CourseList from './CourseList'

export default {
  name: 'CoursesManagement',
  mixins: [CommonHelpers],
  components: {
    CourseCreator,
    CourseList
  },

  props: {},

  data: function () {
    return {
      refreshTrigger: 0,
      selectedFirst: true,
      selectedTab: 0
    }
  },

  mounted() {
  },

  watch: {
  },

  computed: {
  },

  methods: {
    courseAdded() {
      this.refreshTrigger++;
      this.selectedTab = 0;
    },

    courseRemove() {
      this.refreshTrigger++;
    }
  }
}
</script>

<style scoped>
.card {
  overflow: visible !important;
}
</style>
