<template>
  <div class="pl-1 pr-1">
    <UserFeedback :target="t('componentNames.courseList')" class="end mr-4 mt-1" :position="'is-left'"></UserFeedback>
    <p class="mb-1">
      <b-button :loading="isLoading" @click="getCourses" size="is-small" icon-left="refresh">{{t('common.refresh')}}</b-button>
    </p>
    <b-table
      paginated
      per-page="12"
      :loading="isLoading"
      :data="courses"
      striped
      hoverable
      detailed
      detail-key="id"
    >
      <b-table-column
        field="name"
        searchable
        sortable
        :label="t('forms.name')"
        v-slot="props"
      >{{ props.row.name }}</b-table-column>
      <b-table-column
        field="level"
        searchable
        sortable
        :label="t('common.level')"
        v-slot="props"
      >{{ mGetLevelCourseName(props.row.level) }}</b-table-column>
      <b-table-column
        field="starts"
        searchable
        sortable
        :label="t('course.start')"
        v-slot="props"
      >{{ mDate(props.row.starts) }}</b-table-column>
      <b-table-column
        field="ends"
        searchable
        sortable
        :label="t('course.end')"
        v-slot="props"
      >{{ mDate(props.row.ends) }}</b-table-column>
      <b-table-column field="tags" searchable sortable :label="t('forms.tags')" v-slot="props">
        <b-taglist>
          <b-tag v-for="tag in props.row.tags" :key="tag" size="is-small">{{tag}}</b-tag>
        </b-taglist>
      </b-table-column>
      <b-table-column field="pdfFile" :label="t('course.syllabus')" v-slot="props">
        <b-button
          icon-left="book-open"
          size="is-small"
          @click="downloadFile(props.row.pdfFile)"
          v-if="props.row.pdfFile"
        >{{t('course.downloadSyllabus')}}</b-button>
      </b-table-column>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p>
              <b-icon icon="package-variant" size="is-large"></b-icon>
            </p>
            <p>{{t('common.noData')}}</p>
          </div>
        </section>
      </template>
      <template slot="detail" slot-scope="props">
        <CourseCreator
          :idCourse="props.row.id"
          @courseUpdate="getCourses"
          @courseDelete="getCourses"
          @course-edited="getCourses"
        ></CourseCreator>
      </template>
    </b-table>
  </div>
</template>

<script>
import { Action } from "../../store/config_actions";
import Commons from "@/mixins/commons";
import TypesHelpers from "@/mixins/types"
import CourseCreator from "./CourseCreator"
import UserFeedback from '@/components/feedbacks/UserFeedback'

export default {
  name: "CourseList",
  mixins: [Commons, TypesHelpers],
  components: {
    CourseCreator,
    UserFeedback
  },

  props: {
    refreshTrigger: { type: Number, required: true }
  },

  watch: {
    refreshTrigger() {
      this.getCourses()
    }
  },

  data: function () {
    return {
      courses: [],
      isLoading: false,
    };
  },

  mounted() {
    this.getCourses();
  },

  methods: {
    downloadFile(props) {
      this.isLoading = true;
      this.$store
        .dispatch(Action.MEDIA_GET_IMAGE, props)
        .then((response) => {
          this.isLoading = false;
          var blob = new Blob([response], { type: 'application/pdf' })
          let url = URL.createObjectURL(blob)
          window.open(url, "_blank", "location=yes,scrollbars=yes")
          URL.revokeObjectURL(url)
        })
        .catch((error) => {
          this.isLoading = false;
          this.mDangerSnack(error.toString());
        });
    },

    /**
     * Download courses
     */
    getCourses() {
      this.isLoading = true;
      this.$store
        .dispatch(Action.COURSE_GET_ALL)
        .then((payload) => {
          this.courses = payload;
          this.isLoading = false;
        })
        .catch((error) => {
          this.mDangerSnack(error.toString());
          this.isLoading = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div.end {
  float: right;
}
</style>
